import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Images from '../img/Images';
import DownloadBlocks from './downloadBlocks';

const Footer = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query JurisdictionFooterQuery {
        keyMessages: datoCmsHome {
          nationalJurisdiction {
            slug
            bodyContent {
              ... on DatoCmsHeading {
                id
                keyMessage
              }
            }
          }
        }
        featureArticles: allDatoCmsFeatureArticle(
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              title
              slug
              showMenuAndCards
              showAboutMenu
            }
          }
        }
      }
    `}
    render={(data) => {
      let { keyMessages, featureArticles } = data;

      let keyMessagesArr = [];
      let slug = '';
      if (
        keyMessages &&
        keyMessages.nationalJurisdiction &&
        keyMessages.nationalJurisdiction.slug &&
        keyMessages.nationalJurisdiction.bodyContent
      ) {
        keyMessagesArr = keyMessages.nationalJurisdiction.bodyContent.filter(
          (obj) => {
            return obj.keyMessage;
          }
        );
      }
      return (
        <div className='footer-container'>
          <div className='footer-container-wrapper'>
            <DownloadBlocks />

            <div className='footer-main'>
              <div className='centre-container'>
                <div className='inner grid-ten -centre'>
                  <div className='f-header'>
                    <Link to='/' className='nav-logo'>
                      <img src={Images.logoSimple} alt='logo' />
                    </Link>
                  </div>
                  <div className='footer-items grid-container'>
                    <div className='footer-item'>
                      <p className='footer-item-header'>Reforms</p>
                      <div className='footer-sub-container'>
                        {featureArticles.edges.map((elem, i) => {
                          if (elem.node.showMenuAndCards) {
                            return (
                              <p key={i}>
                                <Link
                                  to={`/${elem.node.slug}`}
                                  className='footer-item-sub-header'
                                >
                                  {elem.node.title}
                                </Link>
                              </p>
                            );
                          } else {
                            return '';
                          }
                        })}
                      </div>
                    </div>
                    <div className='footer-item'>
                      <p className='footer-item-header'>About</p>
                      <div className='footer-sub-container'>
                        {featureArticles.edges.map((elem, i) => {
                          if (elem.node.showAboutMenu) {
                            return (
                              <p key={i}>
                                <Link
                                  to={`/${elem.node.slug}`}
                                  className='footer-item-sub-header'
                                >
                                  {elem.node.title}
                                </Link>
                              </p>
                            );
                          } else {
                            return '';
                          }
                        })}
                      </div>
                    </div>
                    <div className='grid-quarter footer-item'>
                      <p className='footer-item-header'>Resources</p>
                      <div className='footer-sub-container'>
                        <p>
                          <Link to='/news' className='footer-item-sub-header'>
                            News
                          </Link>
                        </p>
                        <p>
                          <Link
                            to='/reports-and-documents'
                            className='footer-item-sub-header'
                          >
                            Knowledge Hub
                          </Link>
                        </p>
                        <p>
                          <a
                            href='https://www.aemc.gov.au/calendar'
                            className='footer-item-sub-header'
                            target='_blank'
                          >
                            Calendar
                          </a>
                        </p>
                        <p>
                          <Link
                            to='/subscribe'
                            className='footer-item-sub-header'
                          >
                            Subscribe to our newsletter
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className='grid-quarter footer-item'>
                      <p className='footer-item-header'> Legal</p>
                      <div className='footer-sub-container'>
                        <p>
                          <a
                            className='footer-item-sub-header'
                            href='https://www.aemc.gov.au/terms-use/terms-use-0'
                            target='_blank'
                          >
                            Privacy Policy
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className='grid-quarter footer-item'>
                      <p className='footer-item-header'>Market Bodies</p>
                      <div className='footer-sub-container'>
                        <p>
                          <a
                            className='footer-item-sub-header'
                            href='https://www.aemc.gov.au/'
                            target='_blank'
                          >
                            AEMC
                          </a>
                        </p>
                        <p>
                          <a
                            className='footer-item-sub-header'
                            href='https://aemo.com.au/en'
                            target='_blank'
                          >
                            AEMO
                          </a>
                        </p>
                        <p>
                          <a
                            className='footer-item-sub-header'
                            href='https://aer.gov.au'
                            target='_blank'
                          >
                            AER
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }}
  />
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
