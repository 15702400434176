import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"

const twURL = 'https://twitter.com/intent/tweet?'

const linkedinURL = 'https://www.linkedin.com/sharing/share-offsite/?url='


const fbURL = 'https://www.facebook.com/sharer/sharer.php?u='

const url = 'https://esb-post2025-market-design.aemc.gov.au';

export const SocialLine = ({path}) => {
	let shareUrl = `${url}${path}`;


	return (
		<div>
			<p>
				<a
					href={`${twURL}${shareUrl}`}
					target='_blank'
				>
					<span >Twitter</span>
				</a>
				<a
					href={`${fbURL}${shareUrl}`}
					target='_blank'
				>
					<span >Facebook</span>
				</a>
				<a
					href={`${linkedinURL}${shareUrl}`}
					target='_blank'
				>
					<span >LinkedIn</span>
				</a>
			</p>
		</div>
	)
}

export const SocialBox = (props) => {
	let { shareId, path } = props;

	let shareUrl = `${url}${path}`;

	return (
		<div className='social_box'>
			<h5>SHARE THIS SECTION</h5>
			<div className='flex-row'>
				<a
					href={`${twURL}${shareUrl}%23${shareId}`}
					target='_blank'
				>
					<span >Tw</span>
				</a>
				<a
					href={`${fbURL}${shareUrl}%23${shareId}`}
					target='_blank'
				>
					<span >Fb</span>
				</a>
				<a
					href={`${linkedinURL}${shareUrl}#${shareId}`}
					target='_blank'
				>
					<span >Ln</span>
				</a>
			</div>
		</div>
	)
}
