import React from 'react';
import Data from '../utils/data';


const Table = ({ dataObj, titleBoolean }) => {
	let data = dataObj.csv;
	if (!data) {
		return false;
	}
	else {
		data = Data.cleanNumbers(data);
		let title = data[2][1];
		let comment = data[4][1];
		let rawData = data.slice(5);

		let format = dataObj.format;
		let size = (dataObj.size/1024).toFixed(2);

		// Build Table Dtat
		let table = `<table>`;

		let colourBool = true;
		let firstCellRowSpan = {
			start: 0,
			height: 0
		};

		const checkBrackets = ( str ) => {
			var s;
			str = str.replace( /[^{}[\]()]/g, '' );
			while ( s != str ) { 
					s = str;
					str = str.replace( /{}|\[]|\(\)/g, '' )
			}
			return !str;
		};

		// Loop through rows
		rawData.forEach((row, i) => {

			table += `<tr>`;

			// Loop through cells
			row.forEach((elem, j) => {
				// Fixes negative values being rendered incorrectly
				rawData[i][j] = rawData[i][j].replace('�', '-');
				rawData[i][j] = rawData[i][j].replace('($', '-$');
				// rawData[i][j] = rawData[i][j].replace(')', '');
				rawData[i][j] = rawData[i][j].replace('(DMO', '(DMO)');
				if (!checkBrackets(rawData[i][j])) {
					rawData[i][j] = rawData[i][j].replace(')', '');
				}

				
				/**
				 * Overall logic
				 * 
				 * Merged cells/rows require colspan/rowspan and for the next cell (merged) to be removed
				 * 
				 * The below iterates over each cell in each row and:
				 * 
				 * if next cell in the row is identical, increase colspan of current and skip next cell
				 * 
				 * if cell below is identical, increase rowspan of current
				 * 
				 * if cell above is identical, skip cell 
				 */

				let firstCol = (j == 0) ? `class='col-0'` : '';

				if (
					i == 0 &&
					j == 0
				) {
					table += `<td data-zero data-color-${colourBool}></td>`;
				} else {
					table += `<td ${firstCol} data-col-${j} data-row-${i} data-color-${colourBool}>${rawData[i][j]}</td>`;
				}
			});
			table += `</tr>`;
		});
		table += '</table>';

		return (
			<div className={'chart-container'}>
				{titleBoolean && (
					<h4>{title}</h4>
				)}
				<div
					dangerouslySetInnerHTML={{ __html: table }}
					className="table-wrapper"
				/>
				<div className="table-comment">
					{
						dataObj.url &&
						<div className={'table-download'}>
							<a href={dataObj.url.replace('https://www.datocms-assets.com/', 'https://esb-post2025-market-design.aemc.gov.au/')} target='blank'>DOWNLOAD TABLE DATA {`[${size} kB ${format.toUpperCase()}]`}</a>
						</div>
					}
					<p className='source'>{comment}</p>
				</div>		
			</div>
		);	
	}
};

export default Table;
