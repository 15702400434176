import React from 'react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet/es/Helmet';
import { create } from 'apisauce';

const onSubmit = (setSuccess, setError, setSubmitted) => async (data) => {
  setSubmitted(true);
  const headers = {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  };

  const api = create({
    baseURL: process.env.GATSBY_AEMC_URL,
    headers: headers,
  });

  const sendData = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone_number: data.phone,
    mobile: data.mobile,
    organisation: data.organisation,
    position: data.position,
    list: [
      {
        key: 'field_esb_newsletter',
        listId: 749674,
        choice: data.weekly,
      },
    ],
  };

  const result = await api.post('/api/esbForm', sendData);
  setSuccess(result.ok);
  setError(!result.ok);
};

export default function SubscribeForm() {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div>
      <Helmet>
        <script
          async
          defer
          src='https://www.google.com/recaptcha/api.js'
          type='text/javascript'
        />
      </Helmet>
      <div>
        <p>
          ESB Update is a weekly newsletter delivering updates for industry
          stakeholders on key ESB workstreams.
        </p>

        <p>Please subscribe to keep up to date on the P2025 reforms.</p>
      </div>
      <form
        className='subscribe-form'
        onSubmit={handleSubmit(onSubmit(setSuccess, setError, setSubmitted))}
      >
        <div className='form-text-field'>
          <label htmlFor='firstName'>First name:</label>
          <input
            {...register('firstName', {
              required: true,
            })}
          />
          {errors.firstName &&
            errors.firstName.type === 'required' &&
            'First name is required'}
        </div>
        <div className='form-text-field'>
          <label htmlFor='lastName'>Last name:</label>
          <input
            {...register('lastName', {
              required: true,
            })}
          />
          {errors.lastName &&
            errors.lastName.type === 'required' &&
            'Last name is required'}
        </div>
        <div className='form-text-field'>
          <label htmlFor='email'>Email:</label>
          <input
            {...register('email', {
              required: true,
            })}
          />
          {errors.email &&
            errors.email.type === 'required' &&
            'Email is required'}
        </div>
        <div className='form-text-field'>
          <label htmlFor='phone'>Phone:</label>
          <input
            {...register('phone', {
              required: true,
            })}
          />
          {errors.phone &&
            errors.phone.type === 'required' &&
            'Phone is required'}
        </div>
        <div className='form-text-field'>
          <label htmlFor='mobile'>Mobile:</label>
          <input
            {...register('mobile', {
              required: true,
            })}
          />
          {errors.mobile &&
            errors.mobile.type === 'required' &&
            'Mobile is required'}
        </div>
        <div className='form-text-field'>
          <label htmlFor='organisation'>Organisation:</label>
          <input
            {...register('organisation', {
              required: true,
            })}
          />
          {errors.organisation &&
            errors.organisation.type === 'required' &&
            'Organisation is required'}
        </div>
        <div className='form-text-field'>
          <label htmlFor='position'>Position:</label>
          <input
            {...register('position', {
              required: true,
            })}
          />
          {errors.position &&
            errors.position.type === 'required' &&
            'Position is required'}
        </div>
        <div className='form-checkbox'>
          <input
            type='checkbox'
            {...register('weekly', {
              required: true,
            })}
          />
          <label htmlFor='weekly'>Subscribe to the ESB weekly newsletter</label>
          {errors.weekly &&
            errors.weekly.type === 'required' &&
            'You have to select this option'}
        </div>
        <div
          className='g-recaptcha'
          data-sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_KEY}
        />
        <br />
        <input
          className='submit'
          type='submit'
          value='Submit'
          disabled={submitted}
        />
        <div>
          {success && <span>Subscribed successfully</span>}
          {error && (
            <span
              style={{
                color: 'red',
              }}
            >
              Something happened, please try again later
            </span>
          )}
        </div>
      </form>
    </div>
  );
}
