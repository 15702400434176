import { Line } from "react-chartjs-2";
import React from 'react';
import Data from "../utils/data";
import { colours } from "../common/color-pool.js";
import { Decimals } from "../common/decimals.js";


class ChartT5 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			legendItems: [],
			chartInstance: [],
		};
		this.chart = [];
		this.legendClickCallback = this.legendClickCallback.bind(this);
	}

	sortArray(data, allRetailers) {
		let sorted = [];
		allRetailers.forEach((r) => {
			if (data.includes(r)) {
				sorted.push(r);
			}
		});

		return sorted;
	}

	legendClickCallback = (item, legendItemIndex) => {
		if (item.currentTarget.getElementsByClassName('text-block')[0].style.textDecoration === 'line-through') {
			item.currentTarget.getElementsByClassName('text-block')[0].style.textDecoration = 'none';
		}
		else {
			item.currentTarget.getElementsByClassName('text-block')[0].style.textDecoration = 'line-through';
		}
		this.chart.map((chart, i) => {
			var dataItem = chart.chartInstance.config.data.datasets[legendItemIndex];
			if (dataItem) {
				if(dataItem.hidden == true){
					dataItem.hidden = false;
				} else {
					dataItem.hidden = true;
				}
			}
			chart.chartInstance.update();
		})
	}


	componentDidMount() {
		const component = this.chart[0];
		component.chartInstance.generateLegend();
		this.setState({ legendItems: component.chartInstance.legend.legendItems});
	}

	render () {
		const find = (key, data) => {
			let obj = data.find(elem => {
				return elem[0] == key;
			});

			if (obj) {
				return obj[1];
			}
			return false;
		}

		let data = [...this.props.data];
		data = Data.cleanNumbers(data);

		let xAxis = find('X axis', data);
		let yAxis = find('Y axis', data);
		const dataObj = data.slice(7);
		var x_axis_labels = dataObj.splice(0, 1);
		x_axis_labels = [...x_axis_labels];
		let labels = x_axis_labels[0].slice(1, x_axis_labels[0].length);



		const titles = ['ACT', 'NSW', 'QLD', 'SA', 'VIC'];

		const dataObj1 = dataObj.splice(0, 4);
		const dataObj2 = dataObj.splice(0, 4);
		const dataObj3 = dataObj.splice(0, 4);
		const dataObj4 = dataObj.splice(0, 4);
		const dataObj5 = dataObj.splice(0, 4);

		let chartArray = [];

		const chartCreator = () => {
			[dataObj1, dataObj2, dataObj3, dataObj4, dataObj5].forEach((year, index) => {
				let datasets = [];
				year.forEach(function (element, i) {
					let dataset = {
						label: element[0],
						data: element.slice(1).map((x) => x * 100),
						backgroundColor: colours.schemeTwo[i],
						borderColor: colours.schemeTwo[i],
						borderWidth: 1,
						fill: false,
						lineTension: 0
					};
					datasets.push(dataset);
				});
	
				const showLeftBar = (this.props.vw <= 750 || index === 0) ? true : false;
				let newID, firstChart;
				if(index === 0) {
					newID = 'myChart'
					firstChart = true;
	
				} else {
					newID = 'myChart' + index;
					firstChart = false
				}
	
				const options = {
					responsive: true,
					aspectRatio: 0.7,
					elements: {
						point:{
							radius: 0
						}
					},
					legend: {
						display: false,
						position: 'bottom'
					},
					tooltips: {
						callbacks: {
							label: function (tooltipItem, data) {
								var label = Decimals(tooltipItem.yLabel) + '%';
								return label;
							},
						},
					},
					title: {
						display: true,
						text: titles[index],
						position: 'top',
					},
					legendCallback: function(chart) {
						let text = [];
						text.push('<div class=\'legend\' style="list-style-type: none; width: 100%;">');
						for (let i=0; i<chart.data.datasets.length; i++) {
							text.push('<div class="legend-block" style="display: inline-block">');
							text.push('<span style="background-color:' + chart.data.datasets[i].backgroundColor + ';margin-left: 10px;  margin-right: 10px; display: inline-block; width: 30px; height: 10px;"> </span>');
							text.push('<span class="text-block" style="font-family: \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif; font-size: 12px; color: #666">' + chart.data.datasets[i].label + '</span>');
							text.push('</div>');
						}
						text.push('</div>');
						return text.join('');
					},
					scales: {
						xAxes: [
							{
								stacked: true,
								scaleLabel: {
									labelString: xAxis,
									display: false
								},
								gridLines: {
									display: true,
									tickMarkLength: 0,
								},
								ticks: {
									display: true,
									autoSkip: false,
									padding: 10
								}
							}
						],
						yAxes: [
							{
								display: true,
								position: 'left',
								scaleLabel: {
									display: false,
									labelString: yAxis
								},
								gridLines: {
									tickMarkLength: 0,
								},
								ticks: {
									beginAtZero: true,
									max: 14,
									padding: 10,
									stepSize: 2.5,
									display: firstChart,
									userCallback: function (item, index) {
										if (item !== 14) {
											return item + '%';
										}
									},
								}
							}
						]
					}
				}
				let chart = {
					labels,
					datasets,
				};
				chartArray.push(
					<div className="one-five" key={index}>
						<Line id={newID} className="myChart" ref={ref => this.chart[index] = ref} data-key={index} data={chart} options={options} height={null} width={null}/>
					</div>
				)
			});
			return chartArray;
		}

		return (
			<div>
				<div className="chart-t5">
					<div className="left-block">{yAxis}</div>
					<div className="center-block">
							{chartCreator()}
					</div>
				</div>
				<div className="chart-footer">
					<p className="chart-footer-title"></p>
					<div className="legend">
						{this.state.legendItems && this.state.legendItems.length &&
							(
								<div className='legend'>
									{this.state.legendItems.map((item, index) => {
										const spanStyle = {
											backgroundColor: item.fillStyle,
										};
										return (
										<div key ={index}className="legend-block" data-value={item.text} onClick={(e) => this.legendClickCallback(e, index)}>
										<span
										style={spanStyle}
										className="legend-icon"></span>
										<span className="text-block">{item.text}</span>
										</div>
										);
									})}
								</div>
									)
								}
					</div>
				</div>
			</div>
		);
	}
}

export default ChartT5;
