import React, { Fragment } from 'react'
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import Img from 'gatsby-image'
import ChartD from "../components/chart-d"
import ChartD2 from "../components/chart-d2"
import ChartG from "../components/chart-g"
import Chart11 from "../components/chart-11"
import ChartT5 from "../components/chart-t5"
import ChartResiendtelec from "../components/chart-resiendtelec";
import ChartMixstackbarline from "../components/chart-mixstackbarline";
import ChartMixstackbarlinegas from "../components/chart-mixstackbarlinegas";
import ChartClcverticalstackbar from "../components/chart-clcverticalstackbar";

import Table from '../components/table'
import Iframe from '../components/iframe'
import TabbedContent from '../components/tabbedContent'
import Images from '../img/Images'
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from 'react-socks'
import Select, { components } from 'react-select'
import CustomSelector from '../components/customSelector'
import { SocialBox } from './socialComponents'
import PymIframe from '../components/pymIframe';
import ReactMarkdown from 'react-markdown';


class BodyContentComponent extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selectField: '',
			charts: {
				D: ChartD,
				D2: ChartD2,
				G: ChartG,
				11: Chart11,
				T5: ChartT5,
				Resiendtelec: ChartResiendtelec,
				Mixstackbarline: ChartMixstackbarline,
				Mixstackbarlinegas: ChartMixstackbarlinegas,
				Clcverticalstackbar: ChartClcverticalstackbar
			},
			vw: (typeof window !== 'undefined' ? window.innerWidth : '')
		}
	}

	getHash = (title) => {
		return title
			.replace(/[^\w\s]/gi, '')
			.toLowerCase()
			.trim()
			.replace(/ /gi, '-')
	}

	render() {
		const { content, social, path } = this.props
		const { charts } = this.state
		const hasWindow = typeof window !== 'undefined';
		let windowWait;
		if (hasWindow) {
			window.addEventListener('resize', function() {
				clearTimeout(windowWait);
				windowWait = setTimeout(this.handleResize, 100);
			})
		}

		const displayContent = content.map((obj, i) => {

			if(!obj) return null;

			let hash;
			if ('sectionTitle' in obj) {
				hash = this.getHash(obj.sectionTitle);
			}

			// Render Key Message
			if ('keyMessage' in obj) {

				hash = this.getHash(obj.keyMessage);
				return <section key={i} id={hash || `content_section_${i}`} className='content_section key-messages'>
					<div>
						<h2 className="key_message">{obj.keyMessage}</h2>
						{social && <SocialBox shareId={hash} path={path}/>}
					</div>
				</section>
			}

			// Render Paragraph
			if ('text' in obj) {
				return <section key={i} id={hash || `content_section_${i}`} className='content_section'>
					{obj.sectionTitle &&
						<div>
							<h3 className="sub_content_title">{obj.sectionTitle}</h3>
							{social && <SocialBox shareId={hash} path={path}/>}
						</div>
					}
					<div
						className="sub_content_body"
					>	
						<div dangerouslySetInnerHTML={{ __html: obj.text }}></div>
					</div>
				</section>
			}

			// Render Image
			if (obj.image) {
				return <section key={i} id={hash || `content_section_${i}`} className='content_section'>
					{obj.sectionTitle && <div>
						<h3 className="sub_content_title">{obj.sectionTitle}</h3>
					</div>}
					<Img fluid={obj.image.fluid} alt="content image" />
				</section>
			}

			// Render Table
			if (obj.table) {
				if(!obj.table) return null;
				return <section
					key={i}
					id={hash || `content_section_${i}`}
					className='content_section'
				>
					{/* <div>
						<h3 className="sub_content_title">{obj.sectionTitle}</h3>
					</div> */}
					<Table
						dataObj={obj.table.file} titleBoolean={true}
					/>
				</section>
			}
			if (obj.chartCollection) {
				return (
					<TabbedContent key={i} data={obj} vw={this.state.vw} id={hash || `content_section_${i}`}></TabbedContent>
				)
			}

		  // Render Chart
			if (obj.chart) {
				let chartType = obj.chart.chartType.title;
				let Chart = charts[chartType];
				let format, size, additionalFormat, additionalSize;

				if (Chart) {
					let description = '';

					if ('chartDescriptionNode' in obj.chart) {
						description = obj.chart.chartDescriptionNode.childMarkdownRemark.html;
					}

					if (obj.chart.file) {
						format = obj.chart.file.format;
						size = (obj.chart.file.size/1024).toFixed(2);
					}

					if (obj.chart.additionalFile) {
						additionalFormat = obj.chart.additionalFile.format;
						additionalSize = (obj.chart.additionalFile.size/1024).toFixed(2);
					}

					const returnChart = () => {
						if (obj.chart.file) {
							return (
								<Chart
									data={obj.chart.file.csv} vw={this.state.vw} chartIndex={i}
								/>
							)
						}
						else {
							return (
								<Chart
									chartIndex={i} vw={this.state.vw}
								/>
							)
						}
					}

					return <section
						key={i}
						id={hash || `content_section_${i}`}
						className='content_section'
					>
						<h4 className="chart-title">{obj.sectionTitle}</h4>


						{returnChart()}

						{
							obj.chart.file && obj.chart.file.url &&
							<a
								href={obj.chart.file.url.replace('https://www.datocms-assets.com/', 'https://esb-post2025-market-design.aemc.gov.au/')}
								target='blank'
								className={'chart-download'}
							>
								DOWNLOAD CHART DATA {`[${size} kB ${format ? format.toUpperCase(): ''}]`}
							</a>
						}
						{
							obj.chart.additionalFile && obj.chart.additionalFile.url &&
							<a
								href={obj.chart.additionalFile.url.replace('https://www.datocms-assets.com/', 'https://esb-post2025-market-design.aemc.gov.au/')}
								target='blank'
								className={'chart-download'}
							>
								DOWNLOAD ADDITIONAL CHART DATA {`[${additionalSize} kB ${additionalFormat ? additionalFormat.toUpperCase(): ''}]`}
							</a>
						}
						{
							description &&
							<div
								className="description"
								dangerouslySetInnerHTML={{
									__html: description
								}}
							/>

						}
						{obj.chart.associatedTable && (
								<Table dataObj={obj.chart.associatedTable.file}
									/>
							)}
					</section>
				}
			}

			// Render Local HTML with iframe
			if (obj.htmlChart) {
					const { sectionTitle, htmlChart  } = obj;
					const displayTitle = sectionTitle && sectionTitle.split('_').join(' ')
					return <section
						key={i}
						id={hash || `content_section_${i}`}
						className='content_section'
					>
					<PymIframe
						id={`${hash}-1`} 
						title = {displayTitle}
						htmlChart = {htmlChart}
					/>
				</section>
			}

			// Render iFrame
			if (obj.link) {
				return <section
					key={i}
					id={hash || `content_section_${i}`}
					className='content_section'
				>
					<Iframe
						data={obj}
					/>
				</section>
			}

		})

		return (
			<Fragment>
				{displayContent}
			</Fragment>
		)
	}
}

BodyContentComponent.propTypes = {
	content: PropTypes.array
}

BodyContentComponent.defaultProps = {
	content: []
}

export default BodyContentComponent
